<template>
<div class="container-fluid ">

    <div class="row">

        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">

                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">

                            <h3 class="vv mb-4">Messages</h3>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-12 bg-accent border-bottom">
            <div class="row">
                <div class="col-8">
                    <ul class="nav nav-tabs px-0 mx-0 border-0" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Completed</a>
                        </li>

                    </ul>
                </div>
                <div class="col d-noneb ">
                    <div class="input-group  w-25n pr-3 pull-right float-right">
                        <div class="input-group-prepend ">
                            <div class="input-group-text">
                                <PhMagnifyingGlass class="text-muted" />
                            </div>
                        </div>
                        <input type="text" class="form-control " v-model="search_text" placeholder="Search Messages...">

                    </div>
                </div>
            </div>

        </div>

        <div class="col-4 pb-5 " v-show="ready">
            <ul class="list-group list-group-flush">

                <li class="list-group-item mb-0 py-2 px-2" v-for="x in messages" :key="x.id">
                    <div class="row">
                        <div class="col-12">
                            <span class="text-bold mb-4">{{truncateString(x.subject,30)}}</span>
                        </div>

                        <div class="col-12 mb-3 mt-1">
                            <span class="text-muted py-4">{{truncateString(x.body,40)}}</span>
                        </div>
                        <div class="col-6 mb-2">
                            <span class="text-muted py-1 "><Avatar class="radius align-middle" :square="true" :size="24" variant="beam"  :name="x.email" :colors="colors" />
                        {{truncateString(x.name,10)}}</span>
                        </div>
                        <div class="col-6 mb-2 text-right">
                            <span class="text-muted py-1 ">{{humandate(x.created_at)}}</span>
                        </div>

                    </div>

                </li>

            </ul>
        </div>
        <div class="col-8 pb-5 vl" v-show="ready">

        </div>
        <div class="col-lg-12 text-center pt-5 animate__animated animate__fadeIn" v-show="!ready">
            <span class="loading-lg  text-center mx-auto"></span>
            <br>
            <br>
            <span class="text-muted pt-5 mt-5">loading...</span>
        </div>
        <div class="col-lg-12" v-show="ready && empty">
            <div class="row justify-content-center text-center  ">
                <div class="col-lg-12  text-center">
                    <PhWallet class="text-primary" :size="100" />
                </div>
                <div class="col-lg-12 pt-2 text-center ">

                    <p>You don't have any Sales, yet ;) </p>

                </div>

            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapMutations
} from 'vuex'
var jsonata = require("jsonata");
var $ = require("jquery");
var _ = require('lodash');
import {
    countryCodeEmoji,
    emojiCountryCode
} from 'country-code-emoji';
import Avatar from "vue2-boring-avatars";
import Snackbar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import moment from "moment"
import {
    PhMagnifyingGlass,
    PhFileX,
    PhX,
    PhClock,
    PhFileDotted,
    PhTrashSimple,
    PhPencilSimple,
    PhShareNetwork,
    PhPlus,
    PhEye,
    PhWallet
} from "phosphor-vue";
var hdate = require('human-date')
export default {
    name: "Beats",
    components: {
        PhMagnifyingGlass,
        PhFileX,
        PhFileDotted,
        PhClock,
        PhTrashSimple,
        PhPencilSimple,
        PhShareNetwork,
        Avatar,
        PhX,
        PhPlus,
        PhEye,
        PhWallet
    },
    data() {
        return {
            todelete: {
                Name: "",
            },
            messages: [],
            colors: ["#FFAD08", "#EDD75A", "#73B06F", "#0C8F8F", "#405059"],
            fbase3: "https://storage.googleapis.com/trillwave/image/300/",
            fbaseo: "https://storage.googleapis.com/trillwave/image/orig/",
            filter: "",
            search_text: "",
            ready: false,
            empty: false,
        };
    },
    mounted() {

        this.GetSales();

    },

    methods: {
        ...mapMutations([
            'setBeats',

        ]),
        truncateString(str, num) {
            if (str.length > num) {
                return str.slice(0, num) + "...";
            } else {
                return str;
            }
        },
        toemoji(x) {
            return countryCodeEmoji(x);
        },
        filterf(fltr) {
            this.search_text = ""
            this.filter = fltr
        },
        humandate(stime) {

            return moment(stime).format('ll');
        },
        scheduled_date(stime) {
            return moment(stime).format('LLLL');
        },
        deletet(beat) {
            this.todelete = beat;
            $("#delete_modal").modal("show");
        },
        customer_value(x) {
            var expression = jsonata("$sum(carts.total_amount)");
            var result = expression.evaluate(x);
            return result;
        },
        GetSales() {
            var baseURI = this.$baseurl + "/contact/messages";
            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };
            this.$http
                .get(baseURI, {
                    headers: config,
                })
                .then((result) => {

                    //this.setBeats(result.data)
                    this.messages = result.data
                    this.ready = true
                    console.log(result.data)
                    //this.keys = Object.keys(this.res[0]);
                });
        },
    },
};
</script>

<style>
</style>
